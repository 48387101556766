import { Link, graphql } from "gatsby";
import React from "react"
import Layout from "../components/Layout/layout";
import { Helmet } from 'react-helmet';
import Author from "../components/Author";
import FeaturedImage from "../components/FeaturedImage";
import Share from "../components/Share";
import MobileAds from "../components/Ads/MobileAds";


export default function Home({ data }) {
  
  let p = data.allWpPost.edges;
  const terms = data.site.siteMetadata.tag;
  const {title, tagline} = data.site.siteMetadata;
  
  const posts = p.filter(v =>  {
    let arr = v.node.terms.nodes.map(a => a.name);
    return arr.some(r=> terms.indexOf(r) >= 0);
  });

  const f = posts[0];
  posts.shift();
  
  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
     <Layout>
            <Helmet>
                <title>{title} - {tagline}</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
            <div className="article-list">
              <div key={f.node.id} className="article p-4 p-md-4 p-xl-0">
                    {f.node.featuredImage !== null ? <FeaturedImage image={f.node.featuredImage.node} /> : ''}
                    <h2><Link to={f.node.slug}>{f.node.title}</Link></h2>
                    <div className="meta-data">
                      <span className="post-date">{f.node.date} | <Author key={f.node.author.node.email} node={f.node.author.node} /></span>
                    </div>
                    <Link to={f.node.slug}><div className="content" dangerouslySetInnerHTML={{ __html: f.node.excerpt }} /></Link>
                    <div className="social-media pt-3 pb-3 d-flex justify-content-start align-items-baseline">
                      <div className="share-page align-items-baseline pr-3">
                        <span>DELA:</span>
                      </div>
                      <div className="flex-grow">
                        <Share key={"share-"+f.node.id} title={f.node.title} url={url + f.node.slug} source={title} />
                      </div>
                    </div>
                </div>
                <MobileAds select={0} />
              {posts.map((n) => (
                  <div key={n.node.id} className="article p-4 p-md-4 p-xl-0">
                    {n.node.featuredImage !== null ? <FeaturedImage image={n.node.featuredImage.node} /> : ''}
                    <h2><Link to={n.node.slug}>{n.node.title}</Link></h2>
                    <div className="meta-data">
                      <span className="post-date">{n.node.date} | <Author key={n.node.author.node.email} node={n.node.author.node} /></span>
                    </div>
                    <Link to={n.node.slug}><div className="content" dangerouslySetInnerHTML={{ __html: n.node.excerpt }} /></Link>
                    <div className="social-media pt-3 pb-3 d-flex justify-content-start align-items-baseline">
                      <div className="share-page align-items-baseline pr-3">
                        <span>DELA:</span>
                      </div>
                      <div className="flex-grow">
                        <Share key={"share-"+n.node.id} title={n.node.title} url={url + n.node.slug} source={title} />
                      </div>
                    </div>
                  </div>
                  
              )) }
        </div>
      </Layout> 
  )
}


export const blogQuery = graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
        tagline
        tag
      }
    },
    allWpPost(sort:{fields:[date], order:DESC}) {
      edges {
        node {
          id
          title
          slug
          excerpt
          date( formatString: "YYYY-MM-DD" )
          featuredImage {
            node {
              localFile{
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid 
                  }
                }  
              }
              description
              altText
            }
          }
          author {
            node {
              lastName
              firstName
              email
              nickname
              name
            }
          }
          terms {
            nodes
            {
              name
            }
          }
        }
      }
    }
    
  }
`